import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/blog-post-layout.js";
import ResizableArea from '../../components/ResizableArea/resizable-area';
import Date from '../../components/Post/date';
import HeroPost from '../../components/Hero/hero-post';
import TwitterBox from '../../components/TwitterBox/twitter-box';
import PostsNavigation from '../../components/Post/posts-navigation';
import GraphQLResultsTable from '../../components/PostExtras/GraphQLResultsTable/graphql-results-table';
import * as styles from './011-radial-gauge.module.css';
import heroImg from '../../images/posts/012/graphql-benchmarks.svg';
import nestJsStateOfJs2023 from '../../images/posts/012/nestjs-state-of-js-2023.png';
import resultsGraph from '../../images/posts/012/performance-of-graphql-stacks.svg';
import sevenLevelsOfStack from '../../images/posts/012/7-levels-of-stack.png';
import sevenLevelsOfStack2x from '../../images/posts/012/7-levels-of-stack@2x.png';
import runtime from '../../images/posts/012/runtime.png';
import runtime2x from '../../images/posts/012/runtime@2x.png';
import library from '../../images/posts/012/library.png';
import library2x from '../../images/posts/012/library@2x.png';
import graphql from '../../images/posts/012/graphql.png';
import graphql2x from '../../images/posts/012/graphql@2x.png';
import typing from '../../images/posts/012/typing.png';
import typing2x from '../../images/posts/012/typing@2x.png';
import jit from '../../images/posts/012/jit.svg';
import opentelemetry from '../../images/posts/012/opentelemetry.svg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <HeroPost gradientFrom="#e6dee9" gradientTo="#fdcbf1" hasTiles={false} mdxType="HeroPost">
  <img src={heroImg} alt="Benchmarking GraphQL solutions in the JS/TS landscape" height="269" width="723" />
    </HeroPost>
    <h1>{`Benchmarking GraphQL solutions in the JS/TS landscape`}</h1>
    <Date date="December 8, 2024" dateMachine="2024-12-08" mdxType="Date" />
    <p>{`We are definitely after GraphQL-hype these days. Articles with (valid) criticism are being published and professionals who understand the strengths of this technology use it to their advantage.`}</p>
    <p>{`If you are in the latter group, you have to decide how you’d like to build your GraphQL stack. What are the pieces in the puzzle? What libraries to use?`}</p>
    <p>{`This article focuses on the `}<strong parentName="p">{`operational speed`}</strong>{` of those stacks.`}</p>
    <h2>{`Performance is important and can vary`}</h2>
    <p>{`I got to the benchmarking topic the same way as others before me - the solution I used was just slow and I was looking for answers and alternatives.`}</p>
    <p>{`In GraphQL world we have a bunch of “primitives” like schema, models, resolvers or data-loaders so all solutions naturally have to gravitate around those. They will differ about some approaches but the core has to be similar because it has to solve similar problems.`}</p>
    <p><strong parentName="p">{`If the things are similar, let the fastest win!`}</strong></p>
    <h2>{`Pieces of the puzzle`}</h2>
    <p>{`It might be surprising but I identified 7 levels for the GraphQL stack. Some are optional but pretty common in mature projects so they should be taken into account.`}</p>
    <div className="container">
  <div className={styles.image}>
    <figure className="text-center">
      <img src={sevenLevelsOfStack} srcSet={`${sevenLevelsOfStack} 1x, ${sevenLevelsOfStack2x} 2x`} alt="7 levels of GraphQL stack" width="80%" />
    </figure>
    <figcaption>Levels 1-3 are mandatory for GraphQL to work.</figcaption>
  </div>
    </div>
    <h3>{`Runtime environment`}</h3>
    <p>{`NodeJS is not the only JS runtime we can use on the server. There are alternatives and quite interesting ones with increasing popularity.`}</p>
    <p className="container paragraph mb-0">We will test:</p>
    <div className="container flex">
  <ul className="unsortedList flex-1 mb-0">
    <li>NodeJS</li>
    <li>Deno</li>
    <li>Bun</li>
  </ul>
  <div className="flex-1 from-700">
    <img src={runtime} srcSet={`${runtime} 1x, ${runtime2x} 2x`} alt="Runtimes logos" width="414px" height="145px" />
  </div>
    </div>
    <h3>{`“Web library/framework”`}</h3>
    <p className="container paragraph mb-0">
  The HTTP module in those environments is ok but too basic for many common
  things that servers have to do. That’s why we use libraries like:
    </p>
    <div className="container flex">
  <ul className="unsortedList flex-1 mb-0">
    <li>Express</li>
    <li>Fastify</li>
    <li>Koa</li>
  </ul>
  <div className="flex-1 from-700">
    <img src={library} srcSet={`${library} 1x, ${library2x} 2x`} alt="Web libraries logos" width="414px" height="145px" />
  </div>
    </div>
    <h3>{`GraphQL library`}</h3>
    <p>{`Once we have a runtime for JS code and a nice way of handling the traffic, we need to start thinking about something that will handle the GraphQL requests.`}</p>
    <p className="container paragraph mb-0">Options are:</p>
    <div className="container flex">
  <ul className="unsortedList flex-1 mb-0">
    <li>Apollo Server</li>
    <li>Mercurius (for Fastify)</li>
    <li>GraphQL Yoga</li>
    <li>graphql-http</li>
  </ul>
  <div className="flex-1 from-700">
    <img src={graphql} srcSet={`${graphql} 1x, ${graphql2x} 2x`} alt="GraphQL libraries logos" width="414px" height="145px" />
  </div>
    </div>
    <p>{`That’s not everything available on the market. For example, I was thinking about benchmarking `}<inlineCode parentName="p">{`benzene`}</inlineCode>{` as well but I noticed the last activity in the repo to happen 2 years ago. Even if other solutions could rank high, I didn’t want to “recommend” something that I’m not sure if is still maintained.`}</p>
    <h3>{`Schema first vs code first`}</h3>
    <p>{`Most of those servers support both approaches - schema first and code first. After all, what the server cares about is the schema. Either provided (mostly) manually or fully dynamically generated. So theoretically it doesn’t feel like this should matter for the runtime but rather a bit for the build time or start time.`}</p>
    <p>{`The common (modern?) approach is to use TypeScript and generate the schema from the types we already use in the project. This sounds smart, efficient, and shouldn’t affect the runtime speed.`}</p>
    <p>{`Unfortunately, I found this to not to be true - the operation speed is affected for one of the solutions (that’s a spoiler 🙊):`}</p>
    <p className="container paragraph mb-0">What we will test is:</p>
    <div className="container flex">
  <ul className="unsortedList flex-1 mb-0">
    <li>type-graphql</li>
    <li>pothos</li>
  </ul>
  <div className="flex-1 from-700">
    <img src={typing} srcSet={`${typing} 1x, ${typing2x} 2x`} alt="Typing libraries logos" width="414px" height="87px" />
  </div>
    </div>
    <p>{`What is interesting about Pothos is that they state the following in the docs:`}</p>
    <div className="container">
  <blockquote className="blockquote" cite="https://en.wikipedia.org/wiki/JavaScript">
    The core of Pothos adds 0 overhead at runtime
  </blockquote>
    </div>
    <p>{`Almost as if it should be something that differentiates them from the other solution 😉`}</p>
    <h3>{`Optimising queries execution with graphql-jit`}</h3>
    <p>{`In the path of handling the GraphQL query, the server/engine has to parse the query and execute it against the schema.`}</p>
    <p><inlineCode parentName="p">{`graphql-jit`}</inlineCode>{` optimises this process by compiling the queries into functions and memoizing them so the subsequent execution of the (cached) query is faster. That of course should shine very much in benchmarks that run the same query over and over again but production systems will see the benefits too as they receive the same queries frequently.`}</p>
    <p className="container paragraph mb-0">
  So let’s add another thing to the stack <strong>when possible</strong>:
    </p>
    <div className="container flex">
  <ul className="unsortedList flex-1 mb-0">
    <li>graphql-jit</li>
  </ul>
  <div className="flex-1 text-center align-content-center from-700">
    <img src={jit} alt="Just in time" height="55px" />
  </div>
    </div>
    <p><strong parentName="p">{`Notes:`}</strong></p>
    <ol>
      <li parentName="ol">{`GraphQL Yoga has "`}<a parentName="li" {...{
          "href": "https://the-guild.dev/graphql/yoga-server/docs/features/parsing-and-validation-caching"
        }}>{`Parsing and Validation Caching`}</a>{`” switched on by default. For a fair comparison, I also tested the performance of Yoga when opting out of it. In the results you’ll see those entries with `}<inlineCode parentName="li">{`-no-pav-cache`}</inlineCode>{` suffix.
But it’s not exactly the same as `}<inlineCode parentName="li">{`graphql-jit`}</inlineCode>{` so that variant was included as well with `}<inlineCode parentName="li">{`-jit`}</inlineCode>{` suffix.`}</li>
      <li parentName="ol">{`I couldn’t make ApolloServer v4 working with `}<inlineCode parentName="li">{`graphql-jit`}</inlineCode>{`. I found a way to do it with v3 but the `}<inlineCode parentName="li">{`executor`}</inlineCode>{` function was removed from `}<inlineCode parentName="li">{`v4`}</inlineCode>{`. Maybe some hacky solution could be done via Apollo Gateway to make it working but… I guess ApolloServer is somehow not interested in this solution and instead proposed other “performance techniques” like `}<a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/apollo-server/performance/caching"
        }}>{`Server-Side Caching`}</a>{` or `}<a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/apollo-server/performance/apq"
        }}>{`Automatic Persisted Queries`}</a>{`.
Anyway, if interested, you can follow `}<a parentName="li" {...{
          "href": "https://github.com/zalando-incubator/graphql-jit/issues/219#issuecomment-1685720214"
        }}>{`this issue on GH`}</a>{`.`}</li>
    </ol>
    <h3>{`Observability`}</h3>
    <p className="container paragraph mb-0">
  In non-hobby projects, it’s really worth investing in tools that will help us
  achieve observability of the system (
  <a href="https://youtu.be/TdffkO_DqWk?si=g5oCQTl5l5T2dWY3&amp;t=2547" target="_blank">
    see my colleagues talking about it
  </a>
  ). There are a bunch of solutions that are tied to specific vendors but I don’t
  want this to be a comparison of paid solutions so the only thing we are going to
  test here is:
    </p>
    <div className="container flex">
  <ul className="unsortedList flex-1 mb-0">
    <li>@opentelemetry</li>
  </ul>
  <div className="flex-1 text-center align-content-center from-700">
    <img src={opentelemetry} alt="OpenTelemetry" height="55px" />
  </div>
    </div>
    <h3>{`Frameworks`}</h3>
    <p>{`So far we were going from the bottom to the top. Adding more and more tools. But this strategy requires knowledge - you have to know what you need in the stack. I very much understand that for folks looking for “a GraphQL server for NodeJS” or “how to do GraphQL on the server” that’s not the natural way of going through this.`}</p>
    <p>{`Folks new to the topic will rather look for a more rounded solution (and rightly so). In this spirit we will also give a test to the most popular JS backend framework:`}</p>
    <ul>
      <li parentName="ul">{`NestJS`}</li>
    </ul>
    <div className="container">
  <div className={styles.image}>
    <figure>
      <a href={nestJsStateOfJs2023} target="_blank">
        <img src={nestJsStateOfJs2023} alt="NestJS - the most popular JS back-end framework" width="100%" />
      </a>
      <figcaption>
        <a href="https://2023.stateofjs.com/en-US/other-tools/#backend_frameworks" target="_blank">
          State of JS 2023 - Back-end Frameworks
        </a>
        . Nest is built on top of Express/Fastify and accounts for 30% of responses
        in the survey. It has ~3.6M downloads weekly from npm repository.
      </figcaption>
    </figure>
  </div>
    </div>
    <h2>{`Benchmarking`}</h2>
    <p>{`The repository with the benchmark is located here: `}<a parentName="p" {...{
        "href": "https://github.com/tniezurawski/graphql-benchmarks"
      }}>{`https://github.com/tniezurawski/graphql-benchmarks`}</a></p>
    <p>{`I must say I’m inspired by `}<a parentName="p" {...{
        "href": "https://github.com/benawad/node-graphql-benchmarks/tree/master"
      }}>{`Ben Awad’s work`}</a>{`, and he was inspired by `}<a parentName="p" {...{
        "href": "https://github.com/fastify/benchmarks"
      }}>{`Fastify’s benchmarks`}</a>{`. I was thinking about contributing to Ben’s fork but it was quite out of date comparing to Fastify and I wanted to capture the current state. Whatever that means in terms of techniques, tools and libraries versions. `}<strong parentName="p">{`I discovered some newer version of libraries don’t want to play together anymore.`}</strong>{` Like ApolloServer and JIT I mentioned earlier.`}</p>
    <p>{`I also really wanted to give all runtime environments a try and see if any trends appear.`}</p>
    <p><strong parentName="p">{`Feel free to contribute`}</strong>{`, and I’ll update this post.`}</p>
    <h3>{`Methodology`}</h3>
    <p>{`The plan is simple:`}</p>
    <ol>
      <li parentName="ol">{`Set up the server`}</li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`autocannon`}</inlineCode>{`: `}<inlineCode parentName="li">{`autocannon -c 100 -d 40 -p 10 localhost:3000/graphql`}</inlineCode></li>
    </ol>
    <p>{`Autocannon fires requests agains the running server and gathers information about how fast it is. Here’s the explanation of arguments:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`-c 100`}</inlineCode>{` - 100 concurrent connections to the server. It simulates 100 users connecting to the server at the same time`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`-d 40`}</inlineCode>{` - the test will run for 40 seconds. I have a lot of cases to run, and following Fastify here. It should be enough to spot potential issues with memory leaks or the solution choking at a certain consistent load.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`-p 10`}</inlineCode>{` - this is pipeplining factor. Each connection will send up to 10 requests in sequence, without waiting for responses in between. Useful to test throughput`}</li>
    </ul>
    <p>{`The query is simplistic, yet, it will stress the solutions:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  authors {
    id
    firstName
    lastName
    fullName   # Resolver - string concatenation
    md5        # Resolver - hashing function
    books {    # Async resolver
      id
      name
      genre
    }
  }
}
`}</code></pre>
    <p>{`Keep in mind that all the data is mocked. Even if we have to load data for books of the author, no XHR is made.`}</p>
    <p>{`The dataset is small - 20 authors will be returned. The response has 486 lines of formatted JSON and is rather small.`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "911px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d8bc875bfe71294c784faaab5ffe2495/636c2/json-response-size.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.166666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABK0lEQVQoz41SW46DMAzk/lfbC6zEsv0ABBWPJohXQxI348WIsrSqxeA4tie2k8g5R4B1dsWfDXjvtzXHWPukj8B+5EISpDKavupv+ulzMnahTnc0TRMNw0BKKRrHka7XK9uA+IB5nhkgjWgV6x0NZqJxMTTf75wggcuy8OmAMWYjgb33oaMo/HlB+MIJeiTK84Iul9+gc8qyjINF+r6nOI6prmu2UTmAArhCv7YMLYlt2zIRKhGfAFIUBWmtN1tiXhJWVcVz2wdLHKpJ03QjPOIfobNTCFZMiCS0uCdFFdhH9R8R+kWF2aWUJAm3jfbFL4LZygz3+aeEkNtN8RPBDR7JIGVZUtd1T6N4S9g0DSccyWSN6t4Syub+XUG/grw7zPN4cdHZHD7FWe4DDUOtnDDnOS0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "~9 KB of response of uncompressed text",
            "title": "~9 KB of response of uncompressed text",
            "src": "/static/d8bc875bfe71294c784faaab5ffe2495/636c2/json-response-size.png",
            "srcSet": ["/static/d8bc875bfe71294c784faaab5ffe2495/8ff5a/json-response-size.png 240w", "/static/d8bc875bfe71294c784faaab5ffe2495/e85cb/json-response-size.png 480w", "/static/d8bc875bfe71294c784faaab5ffe2495/636c2/json-response-size.png 911w"],
            "sizes": "(max-width: 911px) 100vw, 911px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`~9 KB of response of uncompressed text`}</figcaption>{`
  `}</figure>
    <h2>{`The results`}</h2>
    <p>{`The fastest solution is 6x faster than the slowest!`}</p>
    <div className="container">
  <div className={styles.image}>
    <figure>
      <a href={resultsGraph} target="_blank">
        <img src={resultsGraph} alt="Results of the benchmark" width="100%" />
      </a>
    </figure>
    <figcaption>
      Results on a bar chart - click to enlarge. See below for a tabular view.
    </figcaption>
  </div>
    </div>
    <p><strong parentName="p">{`The winner of this race is`}</strong>{` `}<strong parentName="p">{`Fastify + Mercurius`}</strong>{` running on NodeJS with `}<strong parentName="p">{`JIT`}</strong>{` switched on.`}</p>
    <p>{`The difference is even more dramatic if we compare the first result that doesn’t use telemetry with the last result which does - then the first is `}<strong parentName="p">{`almost 20x faster!`}</strong>{` 😮`}</p>
    <p>{`Let’s dive in (tabular data available after hot takes).`}</p>
    <h3>{`Hot takes`}</h3>
    <p>{`If you need bite-sizes takes, here’s what is worth knowing:`}</p>
    <ul>
      <li parentName="ul">{`Environment:`}
        <ul parentName="li">
          <li parentName="ul">{`the results are `}<strong parentName="li">{`quite unpredictable`}</strong>{` in my opinion. Some stacks work better on NodeJS, some with Bun, and some with Deno - do you see any correlation here that could explain this?
I guess I know too little about differences between environments to draw any solid conclusion here.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`“Web library/framework”:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`fastify`}</inlineCode>{` is the fastest. Way ahead of `}<inlineCode parentName="li">{`express`}</inlineCode></li>
          <li parentName="ul">{`I’m not sure if `}<inlineCode parentName="li">{`koa`}</inlineCode>{` should be even a part of the comparison. I got a lot of errors of this kind when stressing the solution:`}</li>
        </ul>
      </li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`Error: write EPIPE
      at afterWriteDispatched (node:internal/stream_base_commons:159:15)
      at writevGeneric (node:internal/stream_base_commons:142:3)
      at Socket._writeGeneric (node:net:955:11)
      at Socket._writev (node:net:964:8)
      at doWrite (node:internal/streams/writable:596:12)
      at clearBuffer (node:internal/streams/writable:775:5)
      at Writable.uncork (node:internal/streams/writable:531:7)
      at ServerResponse.end (node:_http_outgoing:1141:19)
      at respond (--PATH--/graphql-benchmarks/node_modules/koa/lib/application.js:302:44)
      at handleResponse (--PATH--/graphql-benchmarks/node_modules/koa/lib/application.js:184:34)
`}</code></pre>
    <p>{`To my understanding it fails under the heavy load of autocannon. It still was able to handle the traffic to some extend but I’m not sure of that result(s) and would stay away from Koa when using GraphQL.
The errors were thrown for both koa-apollo and koa-graphql-api stacks.`}</p>
    <ul>
      <li parentName="ul">{`GraphQL library:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`apollo-server`}</inlineCode>{` is the slowest and dragging down other tech`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`mercurius`}</inlineCode>{`, together with `}<inlineCode parentName="li">{`fastify`}</inlineCode>{` is very fast`}</li>
        </ul>
      </li>
      <li parentName="ul">{`JIT:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`grapqhl-jit`}</inlineCode>{` - every solution with JIT is faster and all the fastest solutions use it`}</li>
          <li parentName="ul">{`having in mind the above, it’s really bad for ApolloServer to not support it 🤷‍♂️`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Schema first vs code first`}
        <ul parentName="li">
          <li parentName="ul">{`I’m surprised to learn that `}<inlineCode parentName="li">{`type-graphql`}</inlineCode>{` adds an overhead. Although, it is not super high and oscillate between 3% to 5%.
Would you accept 3-5% performance drop to use types with `}<inlineCode parentName="li">{`type-graphql`}</inlineCode>{`?`}</li>
          <li parentName="ul">{`Pothos on the other hand is responsible for ~1% of performance degradation so I think it might be just a noise in results. It very likely holds the promise of adding 0 overhead at runtime.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Observability:`}
        <ul parentName="li">
          <li parentName="ul">{`gathering telemetry data is costly - as expected, but average `}<strong parentName="li">{`drop of 80% speed for every solution is ridiculous`}</strong>{`!`}
            <ul parentName="li">
              <li parentName="ul">{`the issue is with gathering data about resolvers`}</li>
              <li parentName="ul">{`not tracking trivial resolver spans (results with `}<inlineCode parentName="li">{`-itrs`}</inlineCode>{` suffix) makes it a bit better - whenever a field is returned 1:1 without a function involved then we don’t track it. TBH, not much interesting info there and from my experience you’ll see those spans being “resolver” in `}<inlineCode parentName="li">{`1ms`}</inlineCode></li>
              <li parentName="ul">{`not tracking resolver spans in general (results with `}<inlineCode parentName="li">{`-irs`}</inlineCode>{` suffix) is a big deal - when you stop tracking resolvers, you will observe a drop of `}<strong parentName="li">{`about 13% performance speed`}</strong>{`.`}</li>
              <li parentName="ul">{`so not tracking resolvers seems to be a must otherwise, who can accept 80% slowdown?`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Frameworks:`}
        <ul parentName="li">
          <li parentName="ul">{`If you follow NestJS tutorial on building GraphQL server you’ll end up with express and ApolloServer which `}<strong parentName="li">{`is the slowest solution`}</strong>{` here`}</li>
          <li parentName="ul">{`Even the absolute killer solution (`}<inlineCode parentName="li">{`fastify-mercurius-jit`}</inlineCode>{`) is dragged down by NestJS and sees 40% decrease in performance`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/tniezurawski/nestjs-resolvefield-performance"
            }}>{`I debugged the framework and I see two bottlenecks`}</a>{`. One of them can be mitigated by not using `}<inlineCode parentName="li">{`@Parent()`}</inlineCode>{` decorator in resolvers but the other is unavoidable as it touches resolvers themselves.
“Deparentification” accounts for almost 30% performance boost. So it’s definitely worth doing. But it’s a pity that the poor performance of `}<inlineCode parentName="li">{`@Parent()`}</inlineCode>{` is not documented properly.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Absolute values of latency:`}
        <ul parentName="li">
          <li parentName="ul">{`Comparing requests per second is important but have in mind the absolute values of latency. The slowest solution with telemetry needs 526ms to process a request without any calls being done to DB or external/internal services! `}<strong parentName="li">{`That's very bad.`}</strong></li>
        </ul>
      </li>
    </ul>
    <h3>{`In a table`}</h3>
    <GraphQLResultsTable mdxType="GraphQLResultsTable" />
    <h2>{`Conclusions`}</h2>
    <p>{`No matter if you use a framework like NestJS, or not, `}<inlineCode parentName="p">{`fastify`}</inlineCode>{`, `}<inlineCode parentName="p">{`mercurius`}</inlineCode>{` and `}<inlineCode parentName="p">{`graphql-jit`}</inlineCode>{` should be your choice.
When gathering telemetry data, don't run on defaults, switch off resolvers tracking and switch it on only when you really
have to dive into some issues.`}</p>
    <p>{`Best of luck!`}</p>
    <div className="container mt-40 mb-55">
  <TwitterBox threadLink="https://x.com/tomekdev_/status/1865790242416124367" mdxType="TwitterBox" />
    </div>
    <PostsNavigation prevTitle="Interactive Radial Gauge" prevLink="/posts/interactive-radial-gauge-in-react" mdxType="PostsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      